import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/button';
import AdminTimer from '../../../RenderingComponents/AdminTimer';
import AdminTopList from '../../../RenderingComponents/AdminTopList';
import InfoCard from '../../../RenderingComponents/InfoCard';

const TimerPhaseEnum = {
    INITIAL: 0,
    IN_PROGRESS: 1,
    FINISHED: 2,
};
Object.freeze(TimerPhaseEnum); 

/**
 * @param {Boolean} startTimer
 * @param {String} title
 * @param {String} quizId
 * @param {String} id
 * @param {Number} duration
 * @param {Array} answers
 * @param {Function} startQuestion
 * @param {Boolean} disableButtons
 * @param {Function} setDisableButtons
 * @param {Array} resultList
 */
const QuizQuestionAndAnswers = ({
    startTimer, 
    title, 
    quizId, 
    id, 
    duration, 
    answers, 
    startQuestion, 
    disableButtons, 
    setDisableButtons,
    resultList
}) => {
    const [timerPhase, setTimerPhase] = useState(startTimer ? 
        TimerPhaseEnum.IN_PROGRESS : 
        TimerPhaseEnum.INITIAL);

    useEffect(() =>{
        if(timerPhase === TimerPhaseEnum.IN_PROGRESS){
            setDisableButtons(true);
        };
    }, [timerPhase]);

    const MapAnswers = (answers) => {
        const mappedAnswers = answers
            .map((element, index) => {
    
                let answerClassName = "wrongAnswer";
                if(element.isCorrectAnswer === true){
                    answerClassName = "correctAnswer";
                };
    
                return (
                    <li className={answerClassName} key={index}>
                        <span>{element.title}</span>
                    </li>
                );
            });
    
        return mappedAnswers;
    };

    const OnClickStartQuestion = () => {
        startQuestion(quizId, id);
        setTimerPhase(TimerPhaseEnum.IN_PROGRESS);
    };

    let buttonVariant = "";
    let buttonText = "";
    let buttonPermanentDisable = false;
    switch (timerPhase){
        case TimerPhaseEnum.INITIAL:
            buttonVariant = "btn btn-primary";
            buttonText = "Start spørsmål"
            break;
        case TimerPhaseEnum.IN_PROGRESS:
            buttonVariant = "btn btn-info";
            buttonText = "Spørsmål gjennomføres";
            break;
        case TimerPhaseEnum.FINISHED:
            buttonVariant = "btn btn-danger";
            buttonText = "Gjennomført";
            buttonPermanentDisable = true;
            break;
        default:
            break;
    };

    const timerComponent = timerPhase === TimerPhaseEnum.IN_PROGRESS ?
        (<AdminTimer
            secondsToCountdown={duration}
            finishTimer={() => setTimerPhase(TimerPhaseEnum.FINISHED)}
        />)
        : null;

    const adminTopListComponent = timerPhase === TimerPhaseEnum.FINISHED ? 
        (<AdminTopList
            resultList = {resultList}
        />) 
        : null;

    return(
        <li className="mt-3 col-12 col-md-8 offset-md-2">
            <InfoCard
                cardHeaderComponents={(
                    <Button
                        variant={buttonVariant}
                        onClick={OnClickStartQuestion}
                        disabled={buttonPermanentDisable || disableButtons}
                        className="quizOverviewButton"
                    >
                        {buttonText}
                    </Button>
                )}
                
                cardTextModel={{
                    title: (<>{title}</>),
                    description: "Svaralternativ:"
                }}

                cardBodyComponents={(
                    <div>
                        <ul className="list-unstyled">
                            {MapAnswers(answers)}
                        </ul>
                            {adminTopListComponent}
                    </div>
                )}

                iconComponentClassName={"d-md-flex col col-md-4 order-0 order-md-2"}
                iconComponent={timerComponent}
            />
        </li>
    );
};

export default QuizQuestionAndAnswers;