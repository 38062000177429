import React from 'react';
import {Button, Modal} from 'react-bootstrap';

/**
 * @param {bool} showModal
 * @param {string} message
 * @param {string} confirmationButtonText
 * @param {function} functionOnConfirm
 * @param {function} functionOnAbort
 */
const ConfirmationModal = (
    {
        showModal,
        message,
        confirmationButtonText,
        functionOnConfirm,
        functionOnAbort
    }
) => {

  return (
    <Modal 
        show={showModal}
        centered
        className="confirmationModal"
    >
        <Modal.Header>
            <Modal.Title>
                Pass på!
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => functionOnAbort()}>
                Avbryt
            </Button>
            <Button variant="primary" onClick={() => functionOnConfirm()}>
                {confirmationButtonText}
            </Button>
        </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;