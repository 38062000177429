import React from 'react';

/**
 * @param {Number} timeLeft
 */
const Timer = ({timeLeft}) => {
    return (
        <div className="timer-number text-center">
            <span>{timeLeft}</span>
        </div>
    );
};

export default Timer;