import React, { useState } from 'react';
import { Button, Modal, Form, Card, Col, Row, Container } from 'react-bootstrap';
import ConfirmationModal from '../ConfirmationModal';

/**
 * @param {Object} question
 * @param {Function} deleteAnswer
 * @param {Function} addAnswer
 * @param {Function} updateQuestionAndAnswers
 */
const QuestionEditingModal = ({
    question,
    deleteAnswer,
    addAnswer,
    updateQuestionAndAnswers
}) => {
    const [showModal, setModalShow] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [answerToDelete, setAnswerToDelete] = useState("");
    const [showNewAnswerInput, setShowNewAnswerInput] = useState(false);

    const checkForUnsavedChanges = () => {
        if (showNewAnswerInput === true){
            setShowUnsavedChangesModal(true);
            return;
        }

        handleClose();
    }

    const handleClose = () => {
        setModalShow(false);
        setShowNewAnswerInput(false);
        setShowUnsavedChangesModal(false);
    }

    const saveChanges = (event) => {
        updateQuestionAndAnswers(event);
        handleClose();
    }

    const saveAnswer = (event) => {
        setShowNewAnswerInput(false);
        addAnswer(event);
    }

    const handleShowDeleteConfirmation = (answerId) => {
        setAnswerToDelete(answerId);
        setShowDeleteConfirmationModal(true);
    };

    const handleAnswerDeletion = () => {
        deleteAnswer(answerToDelete);
        setShowDeleteConfirmationModal(false);
    };

    const MapAnswers = (question) => {
        return question.answers
            .map((element, index) => {
                return (
                    <Card key={index} border='secondary' className="mt-3">
                        <Card.Body>
                            <Row>
                                <Col xs={12} lg={8}>
                                    <Form.Group controlId={"quiz-answer-" + element.id}>
                                        <Form.Label>Svar {index + 1}</Form.Label>
                                        <Form.Control type="text" defaultValue={element.title} data-questionid={element.id} />
                                    </Form.Group>
                                </Col>

                                <Col xs={4} lg={1}>
                                    <Form.Group controlId={"quiz-answer-is-correct-" + element.id}>
                                        <Form.Label>Riktig</Form.Label>
                                        <Form.Check type="checkbox" defaultChecked={element.isCorrectAnswer}/>
                                    </Form.Group>
                                </Col>

                                <Col xs={8} lg={2}>
                                    <Form.Group controlId={"quiz-answer-order-" + element.id}>
                                        <Form.Label>Rekkefølge</Form.Label>
                                        <Form.Control type="number" defaultValue={element.order}/>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} lg={1} className='mt-md-3'>
                                    <div className='d-flex justify-content-end mt-3'>
                                        <Button variant="danger" onClick={() => handleShowDeleteConfirmation(element.id)}>
                                            Slett
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                );
            });
    };

    const NewAnswerFields = () => {
        if (showNewAnswerInput === false) return;

        return(
            <Form id="question-adding-form" onSubmit={saveAnswer}>
                <Row>
                    <Col xs={12} md={8}>
                        <Form.Control type="text" name="question-id" value={question.id} readOnly hidden/>

                        <Form.Group controlId="add-answer-title">
                            <Form.Label>Svar</Form.Label>
                            <Form.Control type="text" name="answer-title" placeholder={"Teksten i svaralternativet"} required />
                        </Form.Group>
                    </Col>
                    
                    <Col xs={6} md={1}>
                        <Form.Group controlId="add-answer-is-correct">
                            <Form.Label>Riktig</Form.Label>
                            <Form.Check type="checkbox" name="answer-is-correct" defaultChecked={false} />
                        </Form.Group>
                    </Col>
                    
                    <Col xs={6} md={2}>
                        <Form.Group controlId="add-answer-order">
                            <Form.Label>Rekkefølge</Form.Label>
                            <Form.Control type="number" name="answer-order" placeholder="1" required/>
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={1} className='mt-md-3'>
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="success" type="submit">
                                Lagre
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    }

    return (
        <>
            <Button className="question-editing-button" 
                variant="primary" 
                onClick={() => setModalShow(true)}
            >
                Rediger
            </Button>

            <Modal className='question-editing-modal' show={showModal} onHide={checkForUnsavedChanges}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Rediger spørsmål
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form id="question-editing-form" onSubmit={saveChanges}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Control type="text" name="question-id" value={question.id} readOnly hidden />

                                    <Form.Group className="mb-3" controlId="question-title">
                                        <Form.Label>Spørsmål</Form.Label>
                                        <Form.Control type="text" name="question-title" defaultValue={question.title} />
                                    </Form.Group>

                                    <Form.Group className="mb-5" controlId="question-order">
                                        <Form.Label>Rekkefølge</Form.Label>
                                        <Form.Control type="number" name="question-order" defaultValue={question.order} />
                                    </Form.Group>

                                    {MapAnswers(question)}
                                </Col>

                                <Col xs={12} md={{ span: 3, order: 2, offset: 6 }}>
                                    <div className="d-inline-flex d-md-flex justify-content-end">
                                        <Button className="mt-3" variant="primary" type="submit">
                                            Lagre endringer
                                        </Button>
                                    </div>
                                </Col>

                                <Col xs={12} md={{ span: 3, order: 1 }}>
                                    <Button className='mt-3 mb-3' variant="success" onClick={() => setShowNewAnswerInput(!showNewAnswerInput)}>
                                        Nytt svaralternativ
                                    </Button>
                                </Col>
                             </Row>
                        </Form>
                        <Row>
                            <Col xs={12} className="mt-3">
                                {NewAnswerFields()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={checkForUnsavedChanges}>Lukk</Button>
                </Modal.Footer>
            </Modal>

            <ConfirmationModal
                showModal={showDeleteConfirmationModal}
                message={"Er du sikker på at du vil slette elementet? Det går ikke an å angre på denne handlingen"}
                confirmationButtonText={"Slett"}
                functionOnConfirm={() => handleAnswerDeletion()}
                functionOnAbort={() => setShowDeleteConfirmationModal(false)}
            />

            <ConfirmationModal 
                showModal={showUnsavedChangesModal}
                message={"Du har endringer som ikke er lagret. Vil du likevel lukke vinduet?"}
                confirmationButtonText={"Lukk"}
                functionOnConfirm={() => handleClose()}
                functionOnAbort={() => setShowUnsavedChangesModal(false)}
            />
        </>
    );
}

export default QuestionEditingModal;