import React from 'react';
import Button from 'react-bootstrap/button';

/**
 * @param {string} size 
 * @param {string} variant
 * @param {string} text
 * @param {string} buttonClassName
 * @param {function} onClick
 */
const CreateQuizButton = (props) =>{
    return (
        <Button
            variant={props.variant}
            size={props.size}
            onClick={() => props.onClick()}
            className={props.buttonClassName}
        >
            {props.text}
        </Button>
    );
};

export default CreateQuizButton;