import React, {useState} from 'react';
import Button from 'react-bootstrap/button';
import QuizQuestionAndAnswers from './SubComponents/QuizQuestionAndAnswers';

const signalR = require("@microsoft/signalr");

let connection = new signalR
    .HubConnectionBuilder()
    .withUrl("/question")
    .withAutomaticReconnect()
    .build();  

/**
 * @param {Array} questions
 * @param {Function} startQuestion
 * @param {Function} returnToAdminPage
 */
const QuizStartedOverview = ({
    questions,
    startQuestion,
    returnToAdminPage
}) => {
    const [disableButtons, setDisableButtons] = useState(false); 
    const [scoreModelList, setScoreModelList] = useState([]);

    //Receiving SignalR data
    if(connection.state === signalR.HubConnectionState.Disconnected){
        connection.start()
        .then(() => {
            connection.invoke("AddAdminToQuizGroup");
        });   
    }
    connection.on("SendScoresToAdmin", (scoreData) => {
        setDisableButtons(false);
        addScoreModel(scoreData)
    });

    const addScoreModel = (newScoreModel) => setScoreModelList(oldScoreModel => [...oldScoreModel, newScoreModel]);
    const findScoreModel = (questionId) => {
        if (scoreModelList.length === 0)
        {
            return [];
        }

        const scoreModel = scoreModelList.find(scoreModel => scoreModel.questionId === questionId);
        
        if(scoreModel === undefined)
        {
            return [];
        }

        return scoreModel.scores;
    };

    const MapQuestions = (questions) => {
        const mappedQuestions = questions
            .map((element, index) => {

                let startTimer = false;
                if(index === 0){
                    startTimer = true;
                };

                const actualResultList = findScoreModel(element.id);

                return(
                    <QuizQuestionAndAnswers key={index}
                        startTimer={startTimer}
                        title={element.title}
                        quizId={element.quizId}
                        id={element.id}
                        duration={element.duration}
                        answers={element.answers}
                        startQuestion={startQuestion}
                        disableButtons={disableButtons}
                        setDisableButtons={setDisableButtons}
                        resultList={actualResultList}
                    />
                );
            });
        
        return mappedQuestions;
    };

    return (<>
        <header className="row">
            <div className="col-2">
                <Button variant="primary" onClick={returnToAdminPage}>
                    Gå tilbake
                </Button>
            </div>
            <div className="col-6 offset-1">
                <h1>
                    Alle spørsmål
                </h1>
            </div>
        </header>
        <section className="row">
            <ul className="questionAdminList list-unstyled">
                {MapQuestions(questions)}
            </ul>
        </section>
    </>);
};

export default QuizStartedOverview;