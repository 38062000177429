import React, { useState, useEffect } from 'react';
import { SecureGET, SecurePOST, SecurePATCH, SecureDELETE } from '../Utilities/FetchApiMethods';
import QuizStatusEnum from './Quiz/QuizStatusEnum';
import QuizAdminMediator from '../QuizAdminMediator';
import QuizAdminViewEnum from './Quiz/QuizAdminViewEnum';
import '../../AdminPage.css';

const ApiBaseUrl = "/api/QuizAdmin/";
const GetAllQuizzesUrl = ApiBaseUrl + "GetAllQuizzes";
const GetQuizForEditingUrl = ApiBaseUrl + "GetQuizForEditing/";
const ChangeQuizStatusUrl = ApiBaseUrl + "ChangeQuizStatus/";
const StartNextQuestionUrl = ApiBaseUrl + "StartNextQuestion/";
const GetAllQuestionsUrl = ApiBaseUrl + "GetAllQuestions/";
const GetAllQuizActivitiesUrl = ApiBaseUrl + "GetAllQuizActivities";
const AddQuizUrl = ApiBaseUrl + "AddQuiz";
const UpdateQuizUrl = ApiBaseUrl + "UpdateQuiz";
const AddQuestionUrl = ApiBaseUrl + "AddQuestion";
const UpdateQuestionAndAnswersUrl = ApiBaseUrl + "UpdateQuestionAndAnswers";
const DeleteQuestionUrl = ApiBaseUrl + "DeleteQuestion/";
const AddAnswerUrl = ApiBaseUrl + "AddAnswer";
const DeleteAnswerUrl = ApiBaseUrl + "DeleteAnswer/";

/**
 * Loops through the given Form Event Target and fetches the answer fields. 
 * Returns list of models
 */
const CompileAnswerInsertModel = (formEventTarget, numberOfFormElementsPerQuestion = 4) => {
    const answerList = [];

    // Go through all the answers and get their form values. 
    // Jump to next input section for each loop iteration, and get all relevant inputs for each section.
    for (let index = 3; index < formEventTarget.length; index = index + numberOfFormElementsPerQuestion) {
        const formInput = formEventTarget[index];
        
        // Get the question id. Skip to next iteration if undefined
        const inputQuestionId = formInput.dataset.questionid;
        if (inputQuestionId === undefined) {
            continue;
        }

        // Use index + n to get values. index + 3 is a button, that we don't need here. 
        const answerTitle = formEventTarget[index].value;
        const answerIsCorrect = formEventTarget[index + 1].checked;
        const answerOrder = formEventTarget[index + 2].value;

        const answerInsertModel = {
            answerId: inputQuestionId,
            answerTitle: answerTitle,
            isCorrectAnswer: answerIsCorrect,
            order: answerOrder
        };

        answerList.push(answerInsertModel);
    }

    return answerList;
}


const QuizAdminPage = ({authInstance}) => {
    const [quizzes, setQuizzes] = useState({});
    const [hasError, setHasError] = useState(false);
    const [hasWarning, setHasWarning] = useState({show: false, warningMessage:""});
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [currentQuizEdit, setCurrentQuizEdit] = useState({});
    const [quizActivities, setQuizActivities] = useState([]);
    const [currentActivity, setCurrentActivity] = useState({});
    const [quizWasUpdated, setQuizWasUpdated] = useState(null);
    const [quizAdminView, setQuizAdminView] = useState(QuizAdminViewEnum.LOADING);

    useEffect(() => {
        GetAllQuizzes();
    }, []);

    //#region API CALLS

    const GetAllQuizzes = () => {
        SecureGET(GetAllQuizzesUrl, authInstance)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };

                return result.json();
            })
            .then(data => {
                setQuizzes(data);
                setQuizAdminView(QuizAdminViewEnum.ADMIN_LANDING);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const GetQuizForEditing = (quizId) => {
        SecureGET(`${GetQuizForEditingUrl}${quizId}`, authInstance)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };

                return result.json();
            })
            .then(data => {
                setCurrentQuizEdit(data);
                setQuizAdminView(QuizAdminViewEnum.EDITING_QUIZ);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const ChangeQuizStatus = (quizId, status) => {
        SecurePATCH(`${ChangeQuizStatusUrl}${quizId}/${status}`, authInstance)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };

                return result;
            })
            .then((result) =>{
                if(result.status === 204){
                    setHasWarning({show:true, warningMessage:"Quizen du prøvde å starte inneholder ingen spørsmål!"});
                    return false;
                };
                GetAllQuizzes();
                setHasWarning({show:false, warningMessage:""});
                return true;

            })
            .then(shouldGetAllQuestions => {
                if(shouldGetAllQuestions === true && status === QuizStatusEnum.Started){
                    GetAllQuestions(quizId);
                }
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const GetAllQuestions = (quizId) => {
        SecureGET(`${GetAllQuestionsUrl}${quizId}`, authInstance)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };
                
                return result.json();
            })
            .then((result) => {
                setQuizQuestions(result);
                setQuizAdminView(QuizAdminViewEnum.QUIZ_OVERVIEW);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const StartQuestion = (quizId, questionId) => {
        SecurePATCH(`${StartNextQuestionUrl}${quizId}/${questionId}`, authInstance)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const GetAllQuizActivities = () => {
        SecureGET(GetAllQuizActivitiesUrl, authInstance)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };

                return result.json();
            })
            .then(result => {
                setQuizActivities(result);
                setQuizAdminView(QuizAdminViewEnum.NEW_QUIZ);
                setCurrentActivity(result[0]);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const SubmitNewQuiz = (formEvent) =>{
        formEvent.preventDefault();

        const form = new FormData(formEvent.target);
        const startTime = new Date(`${form.get("date")}T${form.get("time")}Z`);
        
        const quizInsertModel = {
            activityId: currentActivity.activityId,
            title: form.get("name"),
            description: form.get("description"),
            startTime: startTime
        };

        SecurePOST(AddQuizUrl, quizInsertModel, authInstance)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };

                return result.json();
            })
            .then(result => {
                // Get the created quiz (result = new id), 
                // and direct user to edit page
                GetQuizForEditing(result);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };   

    const UpdateQuiz = (formEvent) => {
        formEvent.preventDefault();

        const form = new FormData(formEvent.target);
        const startTime = new Date(`${form.get("date")}T${form.get("time")}Z`);

        let order = form.get("order");
        if(order === ""){
            order = null;
        };

        const quizUpdateInsertModel = {
            quizId : currentQuizEdit.quizId,
            title : form.get("title"),
            description : form.get("description"),
            order: order,
            startDate : startTime
        };

        SecurePATCH(UpdateQuizUrl, authInstance, quizUpdateInsertModel)
            .then(result => {
                if(!result.ok){
                    console.error("Error when updating quiz");
                    throw new Error("Something when wrong when updating quiz");
                }
            })
            .then(() => {
                GetQuizForEditing(currentQuizEdit.quizId);
                setQuizWasUpdated(true);
            })
            .catch(error => {
                console.error(error);
                setQuizWasUpdated(false);
            });
    };

    const AddQuestion = (model) => {

        const addQuestionInsertModel = {
            pageId: currentQuizEdit.quizId,
            title: model.questionTitle,
            order: model.questionOrder,
            description: null
        };

        SecurePOST(AddQuestionUrl, addQuestionInsertModel, authInstance)
            .then((result) => {
                if (!result.ok) {
                    console.error("Error when adding question");
                    throw new Error("Something when wrong when adding new question");
                }
            })
            .then(() => {
                GetQuizForEditing(currentQuizEdit.quizId);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };
    
    const UpdateQuestionAndAnswers = (formEvent) => {
        formEvent.preventDefault();

        const questionId = formEvent.target[0].value;
        const questionTitle = formEvent.target[1].value;
        const questionOrder = formEvent.target[2].value;
        const answerList = CompileAnswerInsertModel(formEvent.target);
        
        const insertModel = {
            questionId: questionId,
            questionTitle: questionTitle,
            order: questionOrder,
            answers: answerList
        };

        SecurePATCH(UpdateQuestionAndAnswersUrl, authInstance, insertModel)
            .then(result => {
                if (!result.ok) {
                    console.error("Error when updating question and answers");
                    throw new Error("Something when wrong when updating question and answers");
                }
            })
            .then(() => {
                GetQuizForEditing(currentQuizEdit.quizId);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const DeleteQuestion = (questionId) => {
        SecureDELETE(`${DeleteQuestionUrl}${questionId}`, authInstance)
            .then(result => {
                if (!result.ok) {
                    throw new Error("Could not delete Question");
                }
            })
            .then(() => {
                GetQuizForEditing(currentQuizEdit.quizId);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const AddAnswerToQuestion = (formEvent) => {
        formEvent.preventDefault();

        const form = new FormData(formEvent.target);
        
        const isCorrectAnswer = form.get("answer-is-correct") === "on" ? true : false;
        
        const insertModel = {
            questionId: form.get("question-id"),
            answerTitle : form.get("answer-title"),
            isCorrectAnswer: isCorrectAnswer,
            order: form.get("answer-order")
        };

        SecurePOST(AddAnswerUrl, insertModel, authInstance)
            .then((result) => {
                if (!result.ok) {
                    console.error("Error when adding answer");
                    throw new Error("Something when wrong when adding new answer");
                }
            })
            .then(() => {
                GetQuizForEditing(currentQuizEdit.quizId);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    };

    const DeleteAnswer = (answerId) => {
        SecureDELETE(`${DeleteAnswerUrl}${answerId}`, authInstance)
        .then(result => {
            if(!result.ok){
                console.error("error");
                throw new Error("Something went wrong.")
            };
        })
        .then(() => {
            GetQuizForEditing(currentQuizEdit.quizId);
        })
        .catch(error => {
            console.error(error);
            setHasError(true);
        });
    };

    //#endregion

    // STATE UPDATES
    
    const ActivateQuiz = (quizId) =>{
        ChangeQuizStatus(quizId, QuizStatusEnum.Active);
    };

    const StartQuiz = (quizId) =>{
        ChangeQuizStatus(quizId, QuizStatusEnum.Started);
    };

    const EndQuiz = (quizId) =>{
        ChangeQuizStatus(quizId, QuizStatusEnum.Finished);
    };

    const DeactivateQuiz = (quizId) =>{
        ChangeQuizStatus(quizId, QuizStatusEnum.Inactive);
    };

    const OverseeQuiz = (quizId) => {
        GetAllQuestions(quizId);
    };

    const EditQuiz = (quizId) =>{
        setQuizAdminView(QuizAdminViewEnum.LOADING);
        GetQuizForEditing(quizId);
        setQuizWasUpdated(null);
    };

    // RENDERING 
    const CreateQuizUpdateMessage = () => {
        if (quizWasUpdated === null) {
            return null;
        };

        let message = "Quizen ble oppdatert";
        let variant = "success";

        if(quizWasUpdated === false){
            message = "Quizen kunne ikke oppdateres. Noe gikk galt";
            variant = "danger";
        };
        
        return {
            message: message,
            variant: variant
        };
    };

    return(
        <QuizAdminMediator
            quizAdminView={quizAdminView}
            hasError={hasError}
            hasWarning={hasWarning}
            GetAllQuizzes={GetAllQuizzes}
            GetAllQuizActivities={GetAllQuizActivities}
            quizzes={quizzes}
            ActivateQuiz={ActivateQuiz}
            EditQuiz={EditQuiz}
            StartQuiz={StartQuiz}
            EndQuiz={EndQuiz}
            OverseeQuiz={OverseeQuiz}
            DeactivateQuiz={DeactivateQuiz}
            SubmitNewQuiz={SubmitNewQuiz}
            setCurrentActivity={setCurrentActivity}
            quizActivities={quizActivities}
            currentActivity={currentActivity}
            currentQuizEdit={currentQuizEdit}
            DeleteAnswer={DeleteAnswer}
            AddAnswerToQuestion={AddAnswerToQuestion}
            AddQuestion={AddQuestion}
            DeleteQuestion={DeleteQuestion}
            UpdateQuiz={UpdateQuiz}
            UpdateQuestionAndAnswers={UpdateQuestionAndAnswers}
            CreateQuizUpdateMessage={CreateQuizUpdateMessage}
            quizQuestions={quizQuestions}
            StartQuestion={StartQuestion}
        />
    );
};

export default QuizAdminPage;
