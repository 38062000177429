import React from 'react';
import CreateQuizButton from '../RenderingComponents/CreateQuizButton';
import Timer from '../RenderingComponents/Timer';
import IsValidProperty from '../Utilities/Validator';

/**
 * @param {string} title
 * @param {function} onClickQuestionAnswer
 * @param {Array} answers 
 * @param {number} timeLeft
 */
const CreateQuestionAndAnswers = (props) => {

    const CreateAnswerButtons = (answers) => {
        const answerButtons = answers.map((element, index) => {
            return (
                <CreateQuizButton
                    text={element.title}
                    key={index}
                    size="lg"
                    variant="primary"
                    buttonClassName="answerButton"
                    onClick={() => props.onClickQuestionAnswer(element.id)}
                />);
        });

        return answerButtons;
    }

    let answers = null;
    if(IsValidProperty(props.answers)){
        answers = CreateAnswerButtons(props.answers);
    };

    return(
        <>
            <div className="row quiz-container question-container">
                <div className="col-12">
                    <Timer timeLeft={props.timeLeft}/>
                </div>

                <div className="col-12">
                    <h2 className="heading text-break text-center">
                        {props.title}
                    </h2>
                    <div className="d-grid gap-3">
                        {answers}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateQuestionAndAnswers;