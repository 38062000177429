import { loginRequest } from '../Auth/authConfig';


async function CreateSecureRequestOptions(authInstance, requestMethod, data = null) {

    // Get the logged in account
    const account = authInstance.getAllAccounts()[0];
    const accessTokenRequest = {
        scopes: loginRequest.scopes,
        account: account
    };

    // Acquire access token for user, and use that to build options object. 
    const options = await authInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
            const accessToken = accessTokenResponse.accessToken;
            const headers = new Headers();
            const bearer = "Bearer " + accessToken;
            headers.append("Authorization", bearer);

            // No data to serialize, so return early
            if (data === null) {
                return {
                    method: requestMethod,
                    headers: headers
                };
            }

            // data to serialize. Set content type to json and return object with serialized data. 

            headers.append("Content-Type", "application/json");

            return {
                method: requestMethod,
                headers: headers,
                body: JSON.stringify(data)
            };
        });

    return options;
}


export async function GET(url = ""){
    const response = await fetch(url, {
        method: 'GET'
    });

    return response;
};

export async function SecureGET(url = "", authInstance){
    const options = await CreateSecureRequestOptions(authInstance, "GET");
    return await fetch(url, options);
}

export async function POST(url = "", data = null){
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    return response;
};

export async function SecurePOST(url = "", data = null, authInstance){    
    const options = await CreateSecureRequestOptions(authInstance, "POST", data);    
    return await fetch(url, options)
}

export async function SecurePATCH(url = "", authInstance, data = null){
    const options = await CreateSecureRequestOptions(authInstance, "PATCH", data);    
    return await fetch(url, options)
}

export async function SecureDELETE(url = "", authInstance){
    const options = await CreateSecureRequestOptions(authInstance, "DELETE");    
    return await fetch(url, options)
}