import React from 'react';
import CreateQuizButton from '../RenderingComponents/CreateQuizButton';
import IsValidProperty from '../Utilities/Validator';

/**
* @param {Array} availableQuizzes
* @param {function} handleClickOnAvailableQuizButton
*/
const CreateAvailableQuizzesButtons = (props) =>{

    const CreateJoinQuizButtons = (availableQuizzes, handleClickOnAvailableQuizButton) => {
        const availableQuizButtons = availableQuizzes.map((element, index) => {
            
            return (
                <CreateQuizButton 
                    variant="primary" 
                    size="lg"
                    key={index}
                    text={element.quizName} 
                    onClick={() => handleClickOnAvailableQuizButton(element.quizId)}
                    buttonClassName="availableQuizButton"
                />
            );
        });

        return availableQuizButtons;
    };

    let availableQuizzes = null;
    let title = "Det er ingen tilgjengelige quizzer.";
    if(IsValidProperty(props.availableQuizzes)){
        availableQuizzes = CreateJoinQuizButtons(
            props.availableQuizzes, 
            props.handleClickOnAvailableQuizButton
        );

        title = "Velg en quiz å delta i!";
    };

    return(
        <>
            <div className="row">
                <div className="col-12">
                    <h1 className="heading text-center">
                        {title}
                    </h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 mx-auto">
                    <div className="d-grid gap-3">
                        {availableQuizzes}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateAvailableQuizzesButtons;