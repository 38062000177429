/**
 * @param {function} onClickFunction The function that execute on the button click
 * @param {string} buttonType Bootstrap type of the button
 * @param {string} buttonText The text inside the button
 */
const SimpleButton = ({
    onClickFunction,
    buttonType,
    buttonText
}) => {
    return (
        <button
            type="button"
            className={"btn btn-" + buttonType} 
            onClick={() => onClickFunction()}
        >
            {buttonText}
        </button>
    );
}

export default SimpleButton;