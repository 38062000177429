import React from 'react';

const CreateConfettiAnimation = () => {
    const confettiDivElements = [];
    const numberOfConfettiDivsToCreate = 10;

    for(let i = 0; i < numberOfConfettiDivsToCreate; i++){
        confettiDivElements.push((<div key={i} className="confetti"/>));
    };

    return (
        <div className="row confetti-container">
            {confettiDivElements.map(x => x)}
        </div>
    );
};

export default CreateConfettiAnimation;