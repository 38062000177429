import React, { useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

const ActivityEditModal = ({
    relevantActivity,
    updateActivityFunc
}) => {
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const checkIfEmpty = (value) => {
        if(value === null) return "";
        return value;
    }
    
    const [activityId, _doNotCallId] = useState(relevantActivity.id);
    const [activityIsActive, setActivityIsActive] = useState(relevantActivity.active);
    const [activityName, setActivityName] = useState(relevantActivity.name);
    const [activityDescription, setActivityDescription] = useState(relevantActivity.description);
    const [activityIsFeatured, _doNotCallFeatured] = useState(relevantActivity.featured);
    const [activityLon, setActivityLon] = useState(relevantActivity.lon);
    const [activityLat, setActivityLat] = useState(relevantActivity.lat);
    const [activityZoomLevel, setActivityZoomLevel] = useState(relevantActivity.zoomLevel);
    const [activityAndroidZoomLevel, setActivityAndroidZoomLevel] = useState(relevantActivity.androidZoomLevel);
    const [activityImageUrl, setActivityImageUrl] = useState(relevantActivity.imageUrl);
    const [activityVideoUrl, setActivityVideoUrl] = useState(relevantActivity.videoUrl);
    const [activityLinkUrl, setActivityLinkUrl] = useState(relevantActivity.linkUrl);
    const [activityLinkText, setActivityLinkText] = useState(relevantActivity.linkText);
    const [activityWebpageUrl, setActivityWebpageUrl] = useState(relevantActivity.webPageUrl);
    const [activityType, _doNotCallType] = useState(relevantActivity.activityType);

    const handleClose = () => {
        setModalIsVisible(false);
    }

    const submitForm = (event) => {
        event.preventDefault();

        const insertModel = {
            id: activityId,
            active: activityIsActive,
            name: activityName,
            description: activityDescription,
            featured: activityIsFeatured,
            lon: activityLon,
            lat: activityLat,
            zoomLevel: activityZoomLevel,
            androidZoomLevel: activityAndroidZoomLevel,
            imageUrl: activityImageUrl,
            videoUrl: activityVideoUrl,
            linkUrl: activityLinkUrl,
            linkText: activityLinkText,
            webpageUrl: activityWebpageUrl
        };

        updateActivityFunc(insertModel);
        handleClose();
    }

    let mapActivityFormElements = null;
    if(activityType === 0 || activityType === null){
        mapActivityFormElements = (<Row>
            <Col xs={12} md={6}>
                <Form.Label htmlFor="activity-edit-lon">Lengdegrad</Form.Label>
                <Form.Control
                    id="activity-edit-lon"
                    type="number"
                    placeholder="60.3913"
                    value={checkIfEmpty(activityLon)}
                    onChange={(e) => setActivityLon(e.target.value)}
                    />
            </Col>

            <Col xs={12} md={6}>
                <Form.Label htmlFor="activity-edit-lat">Breddegrad</Form.Label>
                <Form.Control
                    id="activity-edit-lat"
                    type="number"
                    placeholder="5.3221"
                    value={checkIfEmpty(activityLat)}
                    onChange={(e) => setActivityLat(e.target.value)}
                    />
            </Col>

            <Col xs={12} md={6}>
                <Form.Label htmlFor="activity-edit-zoomlevel">Ios zoom nivå</Form.Label>
                <Form.Control
                    id="activity-edit-zoomlevel"
                    type="number"
                    placeholder="100"
                    value={checkIfEmpty(activityZoomLevel)}
                    onChange={(e) => setActivityZoomLevel(e.target.value)}
                    />
            </Col>

            <Col xs={12} md={6}>
                <Form.Label htmlFor="activity-edit-androidzoomlevel">Android zoom nivå</Form.Label>
                <Form.Control
                    id="activity-edit-androidzoomlevel"
                    type="number"
                    placeholder="100"
                    value={checkIfEmpty(activityAndroidZoomLevel)}
                    onChange={(e) => setActivityAndroidZoomLevel(e.target.value)}
                    />
            </Col>

        </Row>);
    }


    let simplePageActivityFormElements = null;
    if (activityType === 1 || activityType === null){
        simplePageActivityFormElements = (<Row>
            <Col xs={12}>
                <Form.Label htmlFor="activity-edit-videourl">Video url</Form.Label>
                <Form.Control
                    id="activity-edit-videourl"
                    type="text"
                    placeholder="Sett inn linken til videoen som skal vises på aktiviteten"
                    value={checkIfEmpty(activityVideoUrl)}
                    onChange={(e) => setActivityVideoUrl(e.target.value)}
                    />
            </Col>
            <Col xs={12}>
                <Form.Label htmlFor="activity-edit-linkurl">Link url</Form.Label>
                <Form.Control
                    id="activity-edit-linkurl"
                    type="text"
                    placeholder="Sett inn linken som skal vises på aktiviteten"
                    value={checkIfEmpty(activityLinkUrl)}
                    onChange={(e) => setActivityLinkUrl(e.target.value)}
                    />
            </Col>
            <Col xs={12}>
                <Form.Label htmlFor="activity-edit-linktext">Lenketeksten</Form.Label>
                <Form.Control
                    id="activity-edit-linktext"
                    type="text"
                    placeholder="Sett inn lenkteteksten som skal vises på aktiviteten"
                    value={checkIfEmpty(activityLinkText)}
                    onChange={(e) => setActivityLinkText(e.target.value)}
                    />
            </Col>
            <Col xs={12}>
                <Form.Label htmlFor="activity-edit-imageurl">Bilde url</Form.Label>
                <Form.Control
                    id="activity-edit-imageurl"
                    type="text"
                    placeholder="Sett inn linken til bildet som skal vises på aktiviteten"
                    value={checkIfEmpty(activityImageUrl)}
                    onChange={(e) => setActivityImageUrl(e.target.value)}
                    />
            </Col>
        </Row>);
    }

    let webviewActivityFormElements = null;
    if (activityType === 2 || activityType === null){
        webviewActivityFormElements = (<Row>
            <Col xs={12}>
                <Form.Label htmlFor="activity-edit-webpageurl">Nettadressen</Form.Label>
                <Form.Control
                    id="activity-edit-webpageurl"
                    type="text"
                    placeholder="Sett inn lenken til aktiviteten"
                    value={checkIfEmpty(activityWebpageUrl)}
                    onChange={(e) => setActivityWebpageUrl(e.target.value)}
                    disabled
                    />
            </Col>
        </Row>);
    }


    return (
        <>
            <Button className="activity-edit-button" 
                variant="primary"
                onClick={() => setModalIsVisible(true)}
            >
                Rediger
            </Button>

            <Modal className="activity-editing-modal"
                show={modalIsVisible}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Rediger {activityName}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form id="activity-edit-form" onSubmit={submitForm}>
                        
                        <Row>
                            <Col xs={12} md={9}>
                                <Form.Label htmlFor="activity-edit-name">Navn</Form.Label>
                                <Form.Control 
                                    id="activity-edit-name"
                                    className="mb-3"
                                    type="text"
                                    placeholder="Skriv navnet på aktiviteten her"
                                    value={activityName}
                                    onChange={(e) => setActivityName(e.target.value)}
                                    required
                                    />
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="activity-edit-isactive">Aktiv</Form.Label>
                                <Form.Check
                                    id="activity-edit-isactive"
                                    className="mb-3"
                                    size="lg"
                                    type="switch"
                                    label="Sett til aktiv"
                                    checked={activityIsActive}
                                    onChange={(e) => setActivityIsActive(e.target.checked)}
                                    />
                            </Col>
                            <Col xs={12} >
                                <Form.Label htmlFor="activity-edit-description">Beskrivelse</Form.Label>
                                <Form.Control 
                                    id="activity-edit-description"
                                    type="text"
                                    as="textarea" rows={2}
                                    placeholder="Skriv beskrivelsen på aktiviteten her"
                                    value={activityDescription}
                                    onChange={(e) => setActivityDescription(e.target.value)}
                                    />
                            </Col>
                        </Row>
                        
                        {mapActivityFormElements}

                        {simplePageActivityFormElements}

                        {webviewActivityFormElements}

                        <Row>
                            <Col>
                                <Button className="mt-3" variant="success" type="submit">
                                    Lagre
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default ActivityEditModal;