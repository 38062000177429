import React, {useState, useEffect} from 'react';

/**
 * @param {Number} secondsToCountdown
 * @param {Function} finishTimer Will set timer phase to FINISHED when called.
 */
const AdminTimer = ({secondsToCountdown, finishTimer}) => {
    const [timeLeft, setTimeLeft] = useState(secondsToCountdown);
    const [timerIsActive, setTimerIsActive] = useState(true);

    //Timer logic
    useEffect(() => {
        let interval = null;
        if(timerIsActive === true){
            interval = setInterval(() => {
                if(timeLeft < 1){
                    setTimerIsActive(false);
                    finishTimer();
                }
                else{
                    setTimeLeft(timeLeft => timeLeft - 1);
                };
            }, 1000);
        };

        return () => clearInterval(interval);

    }, [timeLeft, timerIsActive]);

    return (
        <div className="timer-number admin-timer text-center">
            <span className="admin-timer-number">{timeLeft}</span>
        </div>
    );
};

export default AdminTimer;