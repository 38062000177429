import React, { useEffect, useState } from 'react';
import {GET, POST} from './components/Utilities/FetchApiMethods';
import './QuizPage.css';
import IsValidProperty from './components/Utilities/Validator';
import QuizPhaseEnum from './components/Utilities/QuizPhaseEnum';
import RenderUserEmoji from './components/RenderingComponents/EmojiRenderer';
import QuizPhaseMediator from './components/QuizPhaseMediator';

const GetAllAvailableQuizzesUrl = "/api/Quiz/GetActiveQuizzes";
const RegisterNewParticipantUrl = "/api/Quiz/RegisterNewParticipant";

const FinalTopListSize = 5;

const signalR = require("@microsoft/signalr");

let connection = new signalR
    .HubConnectionBuilder()
    .withUrl("/question")
    .withAutomaticReconnect()
    .build();   


const QuizPage = () => {
    const [availableQuizzes, setAvailableQuizzes] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [participantData, setParticipantData] = useState({});
    const [quizStartTime, setQuizStartTime] = useState("");
    const [questionData, setQuestionData] = useState({});
    const [quizPhase, setQuizPhase] = useState(QuizPhaseEnum.LOADING);
    const [timeLeft, setTimeLeft] = useState(15);
    const [timerIsActive, setTimerIsActive] = useState(false);
    const [answerResultData, setAnswerResultData] = useState({});
    const [finalResultList, setFinalResultList] = useState([]);
    const [isFinalQuestion, setIsFinalQuestion] = useState(false);

    useEffect(() =>{

        const activityPublicId = getActivityPublicIdFromUrl();
        if(IsValidProperty(activityPublicId) === false)
        {
            setHasError(true);
            console.error("Activity id was not found.");
            return;
        }

        GET(`${GetAllAvailableQuizzesUrl}/${activityPublicId}`)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };

                return result.json();
            })
            .then(data => {
                setAvailableQuizzes(data);
                setQuizPhase(QuizPhaseEnum.INITIAL);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
    }, []);

    //Timer logic
    useEffect(() => {
        let interval = null;
        if(timerIsActive === true){
            interval = setInterval(() => {
                if(timeLeft < 1){
                    if(isFinalQuestion){
                        setQuizPhase(QuizPhaseEnum.FINISHED);
                    }
                    setTimerIsActive(false);
                }
                else{
                    setTimeLeft(timeLeft => timeLeft - 1);
                }
            }, 1000);
        }
        else{
            clearInterval(interval);
        };

        return () => clearInterval(interval);

    }, [timeLeft, timerIsActive]);
   
    const getActivityPublicIdFromUrl = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryParameterKey = "activity_public_id";       
        const activityPublicId = queryParams.get(queryParameterKey);

        return activityPublicId;
    };     

    const setStartTimeFormatted = (quizId) => {
        const startTime = availableQuizzes
            .find(quiz => quiz.quizId === quizId)
            .startTimeFormatted;

        setQuizStartTime(startTime);
    }

    const handleClickOnAvailableQuizButton = (quizId) => {

        const requestData = {
            QuizId: quizId
        }

        POST(RegisterNewParticipantUrl, requestData)
            .then(result => {
                if(!result.ok){
                    console.error("error");
                    throw new Error("Something went wrong.")
                };

                return result.json();
            })
            .then(data => {
                //Render user emoji
                const participantData = {...data};
                participantData.emojiCode = RenderUserEmoji(data.emojiCode);

                setParticipantData(participantData);
                setStartTimeFormatted(data.quizId)
                addParticipantToQuizGroup(data.quizId);

                setQuizPhase(QuizPhaseEnum.WAITING_TO_BEGIN);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            });
        };

    const addParticipantToQuizGroup = (quizId) => {       
        connection
            .start()    // Start connection to SingalR
            .then(() => {

                const requestModel = {
                    quizId: quizId
                };

                connection
                    .invoke("AddParticipantToQuizGroup", requestModel)
                    .catch(err => {
                        return console.error(err.toString());
                    });
            })
            .catch(err => {
                return console.error(err.toString());
            });
    };

    const onClickQuestionAnswer = (answerId) => {
        const answerInsertModel = {
            quizId: questionData.quizId,
            questionId: questionData.id,
            answerId: answerId,
            userId: participantData.userId,
            timeLeft: timeLeft
        };

        connection
            .invoke("RegisterUserAnswer", answerInsertModel)
            .catch(err => {
                setHasError(true);
                return console.error(err.toString());
            });

        setQuizPhase(QuizPhaseEnum.ANSWERED);
    };

    const initiateFinalQuestion = (scoreData) => {
        const finalResultList = scoreData.participants
            .slice(0, FinalTopListSize)
            .map(element => {
                return {
                    score: element.score,
                    position: element.position,
                    userEmoji: RenderUserEmoji(element.userEmoji)
                };
            });
                
        setFinalResultList(finalResultList);
        setIsFinalQuestion(true);
        setTimerIsActive(true);
        setTimeLeft(scoreData.durationToNextQuizStep);
    }

    //Receiving SignalR data
    connection.on("SendQuestionToAllParticipants", questionData => {
        setQuestionData(questionData);
        setQuizPhase(QuizPhaseEnum.QUESTION);
        setTimeLeft(questionData.duration);
        setTimerIsActive(true);
    });

    connection.on("SendScoresToAllParticipants", (scoreData, answers) => {
        const currentUsersScoreData = scoreData.participants
            .find(x => x.userId === participantData.userId);

        const isFinalQuestion = scoreData.isFinalQuestion;

        if (isFinalQuestion === true){
            initiateFinalQuestion(scoreData);
        };

        setAnswerResultData({currentUsersScoreData, answers});
        setQuizPhase(QuizPhaseEnum.RESULT);
    });


    return (
        <QuizPhaseMediator
                availableQuizzes={availableQuizzes}
                handleClickOnAvailableQuizButton={handleClickOnAvailableQuizButton}
                onClickQuestionAnswer={onClickQuestionAnswer}
                userName={participantData.emojiCode}
                expectedStartTime={quizStartTime}
                timeLeft={timeLeft}
                hasError={hasError}
                questionData={questionData}
                answerResultData={answerResultData}
                quizPhase={quizPhase}
                finalResultList={finalResultList}
            />
    );
};

export default QuizPage;