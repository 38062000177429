import { useState, useEffect } from "react";
import QuizAdminPage from './components/Admin/QuizAdminPage';
import ActivityAdminPage from './components/Admin/ActivityAdminPage';
import InfoCard from './components/RenderingComponents/InfoCard';
import SimpleButton from './components/RenderingComponents/SimpleButton';
import MapPinFillIcon from './components/Icons/MapPinFillIcon';
import TrophyIcon from './components/Icons/TrophIcon';
import AdminMenuPageEnum from './components/Admin/AdminMenuPagesEnum';

import './AdminPage.css';

const AdminMenuPage = ({ authInstance }) => {
    const [adminMenuPages, setAdminMenuPages] = useState(AdminMenuPageEnum.LANDING);

    const showQuizAdmin = () => {
        setAdminMenuPages(AdminMenuPageEnum.QUIZ);
    }

    const showActivityAdmin = () => {
        setAdminMenuPages(AdminMenuPageEnum.ACTIVITY);
    }

    const showCrossroads = () => {
        setAdminMenuPages(AdminMenuPageEnum.LANDING);
    }

    useEffect(() => {
        document.body.classList.add('admin-body');
    });

    if (adminMenuPages === AdminMenuPageEnum.QUIZ) {
        return (<>
            <SimpleButton 
                onClickFunction={showCrossroads}
                buttonType={"secondary"}
                buttonText="Gå tilbake"
            />
            <QuizAdminPage authInstance={authInstance} />
        </>);
    }

    if (adminMenuPages === AdminMenuPageEnum.ACTIVITY) {
        return (<>
            <SimpleButton
                onClickFunction={showCrossroads}
                buttonType={"secondary"}
                buttonText="Gå tilbake"
            />
            <ActivityAdminPage authInstance={authInstance} />
        </>);
    }

    return (
        <article className="text-center">
            <header className="row">
                <div className="col-12 mb-3">
                    <h1>Velkommen til adminsidene for Tidi</h1>
                    <h3>Her kan du velge mellom de ulike delene av siden på boksene under. </h3>
                </div>
            </header>
            <section className="row gap-3 justify-content-center">

                <InfoCard
                    iconComponent={<MapPinFillIcon />}
                    cardColSize={"col-10 col-md-5"}
                    cardTextModel={{
                        title: "Her kan du administrere aktiviteter",
                        description: "Dette gjelder kubejakter, aktiviterer som bare er enkle sider, osv"
                    }}
                    cardBodyComponents={
                        <SimpleButton
                            onClickFunction={showActivityAdmin}
                            buttonType="primary"
                            buttonText="Gå til aktivitet admin"
                        />
                    }
                />

                <InfoCard
                    iconComponent={<TrophyIcon />}
                    cardColSize={"col-10 col-md-5"}
                    cardTextModel={{
                        title: "Quiz Admin",
                        description: "Her kan du administrere alt med quizer. Du kan opprette de, legge til spørsmål, " + 
                            "starte de, sende spørsmål til brukeren, og mer."
                    }}
                    cardBodyComponents={
                        <SimpleButton
                            onClickFunction={showQuizAdmin}
                            buttonType="primary"
                            buttonText="Gå til quiz admin"
                        />
                    }
                />

            </section>
        </article>
    );
}

export default AdminMenuPage;