import React from 'react';
import Button from 'react-bootstrap/Button';
import CreateNewQuiz from './Admin/Quiz/CreateNewQuiz';
import QuizAdminViewEnum from './Admin/Quiz/QuizAdminViewEnum';
import QuizEditButton from './Admin/Quiz/QuizEditButton';
import QuizEditPanel from './Admin/Quiz/QuizEditing';
import QuizStartedOverview from './Admin/Quiz/QuizStartedOverview';
import CreateErrorAlert from './RenderingComponents/ErrorAlert';
import CreateLoader from './RenderingComponents/Loader';
import IsValidProperty from './Utilities/Validator';

//#region PRIVATE METHODS

const CreateQuizListItems = (
    quiz,
    primaryButtonLabel,
    secondaryButtonLabel,
    secondaryButtonVariant,
    onClickPrimaryButton,
    onClickSecondaryButton
) => {
    if (IsValidProperty(quiz) === false) {
        return (<li><p>Det er ingen quizer.</p></li>);
    };

    return quiz.map((element, index) => {
        return (
            <QuizEditButton key={index}
                quizName={element.quizName}
                quizId={element.quizId}
                primaryButtonLabel={primaryButtonLabel}
                secondaryButtonLabel={secondaryButtonLabel}
                secondaryButtonVariant={secondaryButtonVariant}
                onClickPrimaryButton={onClickPrimaryButton}
                onClickSecondaryButton={onClickSecondaryButton}
            />
        );
    });
};

const CreateQuizList = (
    quiz,
    title,
    primaryButtonLabel,
    secondaryButtonLabel,
    secondaryButtonVariant,
    onClickPrimaryButton,
    onClickSecondaryButton
) => {
    return (
        <div className="col-12 col-md-3 mt-4">
            <h4>{title}</h4>
            <ul className="list-unstyled">
                {CreateQuizListItems(
                    quiz,
                    primaryButtonLabel,
                    secondaryButtonLabel,
                    secondaryButtonVariant,
                    onClickPrimaryButton,
                    onClickSecondaryButton
                )}
            </ul>
        </div>
    );
};

//#endregion

/**
 * @param {enum} quizAdminView
 * @param {Boolean} hasError
 * @param {Boolean} hasWarning
 * @param {Object} currentActivity
 * @param {Object} currentQuizEdit
 * @param {Array} quizzes
 * @param {Array} quizActivities
 * @param {Array} quizQuestions
 * @param {Function} GetAllQuizzes
 * @param {Function} GetAllQuizActivities
 * @param {Function} ActivateQuiz
 * @param {Function} EditQuiz
 * @param {Function} StartQuiz
 * @param {Function} EndQuiz
 * @param {Function} OverseeQuiz
 * @param {Function} DeactivateQuiz
 * @param {Function} SubmitNewQuiz
 * @param {Function} setCurrentActivity
 * @param {Function} DeleteAnswer
 * @param {Function} AddAnswerToQuestion
 * @param {Function} AddQuestion
 * @param {Function} DeleteQuestion
 * @param {Function} UpdateQuiz
 * @param {Function} UpdateQuestionAndAnswers
 * @param {Function} CreateQuizUpdateMessage
 * @param {Function} StartQuestion
 */
const QuizAdminMediator = (props) => {

    const CreateQuizSection = () => {
        return (
            <section className="row">
                {CreateQuizList(
                    props.quizzes.inactiveQuizzes,
                    "Inaktive quizer",
                    "Aktiver quiz",
                    "Rediger quiz",
                    "secondary",
                    props.ActivateQuiz,
                    props.EditQuiz
                )}

                {CreateQuizList(
                    props.quizzes.activeQuizzes,
                    "Aktive quizer",
                    "Start quiz",
                    "Rediger quiz",
                    "secondary",
                    props.StartQuiz,
                    props.EditQuiz
                )}

                {CreateQuizList(
                    props.quizzes.startedQuizzes,
                    "Startede quizer",
                    "Avslutt quiz",
                    "Administrer quiz",
                    "info administrate-quiz-button",
                    props.EndQuiz,
                    props.OverseeQuiz
                )}

                {CreateQuizList(
                    props.quizzes.finishedQuizzes,
                    "Ferdige quizer",
                    "Deaktiver quiz",
                    "Rediger quiz",
                    "secondary",
                    props.DeactivateQuiz,
                    props.EditQuiz
                )}
            </section>
        );
    };
    
    if(props.hasError === true){
        return (
            <CreateErrorAlert
                message={"Noe gikk galt!"}
            />
        );
    };
        
    let warningComponent = null;
    if (props.hasWarning.show === true) {
        warningComponent = (
            <CreateErrorAlert
                message={props.hasWarning.warningMessage}
                type={"warning"}
            />
        );
    };
    
    switch(props.quizAdminView){
        
        // START PAGE
        case QuizAdminViewEnum.ADMIN_LANDING:
            return (
                <article className="text-center">
                    <header className="row">
                        <div className="col-12">
                            <h1>Velkommen til adminsiden!</h1>
                            <h4>Her kan du administrere alt som har med quiz å gjøre.</h4>
                            <Button
                                variant="primary"
                                onClick={() => props.GetAllQuizActivities()}
                            >
                                Lag ny quiz
                            </Button>
                         </div>
                    </header>
                    <section>
                        {warningComponent}
                    </section>
                    {CreateQuizSection()}
                </article>
            );
        
        // NEW QUIZ
        case QuizAdminViewEnum.NEW_QUIZ:
            return (
                <article className="text-center">
                    <CreateNewQuiz
                        SubmitNewQuiz={props.SubmitNewQuiz}
                        returnToAdminPage={() => props.GetAllQuizzes()}
                        setCurrentActivity={props.setCurrentActivity}
                        quizActivities={props.quizActivities}
                        currentActivity={props.currentActivity}
                    />
                </article>
            );

        // EDITING QUIZ
        case QuizAdminViewEnum.EDITING_QUIZ:
            return (
                <article className="text-center">
                    <QuizEditPanel
                        quiz={props.currentQuizEdit}
                        returnToAdminPage={() => props.GetAllQuizzes()}
                        deleteAnswer={props.DeleteAnswer}
                        addAnswer={props.AddAnswerToQuestion}
                        addQuestion={props.AddQuestion}
                        deleteQuestion={props.DeleteQuestion}
                        updateQuiz={props.UpdateQuiz}
                        updateQuestionAndAnswers={props.UpdateQuestionAndAnswers}
                        apiUpdateMessageModel={props.CreateQuizUpdateMessage()}
                    />
                </article>
            );

        // QUIZ OVERVIEW
        case QuizAdminViewEnum.QUIZ_OVERVIEW:
            return (
                <article className="text-center">
                    <QuizStartedOverview
                        questions={props.quizQuestions}
                        startQuestion={props.StartQuestion}
                        returnToAdminPage={() => props.GetAllQuizzes()}
                    />
                </article>
            );
        
        // LOADING
        case QuizAdminViewEnum.LOADING:
            return (
                <CreateLoader 
                    message={"Laster..."} 
                />
            );

        // ERROR
        default:
            return (
                <CreateErrorAlert 
                    message={"Noe gikk galt!"} 
                />
            );
    };
};

export default QuizAdminMediator;