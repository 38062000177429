const authority = "https://login.microsoftonline.com/657ad8ac-6693-46a2-9c0b-b25a43d2233a";
const applicationId = "3c37b3b9-3ea9-4fe0-a871-f91f52c7cd65";

let baseRedirectUrl = document
    .getElementsByName("aad-redirectUrl")[0]
    .getAttribute("content");

if (baseRedirectUrl === "#{redirectUrl}#") {
    baseRedirectUrl = "https://localhost:5001";
}

export const msalConfig = {
    auth: {
        authority: authority,
        clientId: applicationId,
        redirectUri: baseRedirectUrl + "/admin"
    },
    cache: {
        // This configures where your cache will be stored
        cacheLocation: "sessionStorage", 
        
        // Set this to "true" if you are having issues on IE11 or Edge
        storeAuthStateInCookie: false, 
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        `api://${applicationId}/access_as_user`
    ]
};