import React from 'react';
import IsValidProperty from '../Utilities/Validator';

/**
 * @param {string} expectedStartTime
 * @param {string} userName
 */
const CreateQuizWaitingInfo = (props) =>{
    const CreateExpectedStartTimeHeader = (expectedStartTime) => {
        if(IsValidProperty(expectedStartTime) === false){
            return null;
        };

        return(
            <p className="expected-start-time">
                Forventet starttidspunkt er {expectedStartTime}.
            </p>);
    };

    return(
        <div className="row text-center">
            <div className="col-12">
                <h1 className="heading quiz-starting-heading">
                    Quizen starter snart!
                </h1>

                {CreateExpectedStartTimeHeader(props.expectedStartTime)}

                <h2 className="username-text">Ditt brukernavn er: {props.userName}</h2>
            </div>
        </div>
    );
};

export default CreateQuizWaitingInfo;