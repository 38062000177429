import React from 'react';
import CreateAvailableQuizzesButtons from './QuizPhases/AvailableQuizzesButtons';
import CreateErrorAlert from './RenderingComponents/ErrorAlert';
import CreateQuestionAndAnswers from './QuizPhases/QuestionAndAnswers';
import CreateAnswerResults from './QuizPhases/AnswerResults';
import QuizPhaseEnum from './Utilities/QuizPhaseEnum';
import CreateQuizWaitingInfo from './QuizPhases/QuizWaitingInfo';
import CreateAnsweredQuestionWaitingScreen from './QuizPhases/AnsweredQuestion';
import CreateLoader from './RenderingComponents/Loader';
import CreateFinalResult from './QuizPhases/QuizFinished';

/**
 * @param {Array} availableQuizzes
 * @param {function} handleClickOnAvailableQuizButton
 * @param {function} onClickQuestionAnswer
 * @param {bool} hasError
 * @param {string} userName
 * @param {string} expectedStartTime
 * @param {Object} questionData
 * @param {Object} answerResultData
 * @param {enum} quizPhase
 * @param {number} timeLeft
 * @param {Array} finalResultList
 */
const QuizPhaseMediator = (props) => {

    if(props.hasError === true){
        return (
            <CreateErrorAlert
                message={"Noe gikk galt!"}
            />
        );
    };
    
    switch(props.quizPhase){
        //LIST ALL AVAILABLE QUIZZES IF THERE ARE ANY
        case QuizPhaseEnum.INITIAL:
            return (
                <CreateAvailableQuizzesButtons
                    availableQuizzes={props.availableQuizzes}
                    handleClickOnAvailableQuizButton={props.handleClickOnAvailableQuizButton}
                />);
        
        //WAITING FOR QUIZ TO BEGIN
        case QuizPhaseEnum.WAITING_TO_BEGIN:
            return (
                <CreateQuizWaitingInfo
                    expectedStartTime={props.expectedStartTime}
                    userName={props.userName}
                />);
        
        //QUIZ QUESTION
        case QuizPhaseEnum.QUESTION:
            return (
                <CreateQuestionAndAnswers
                    title={props.questionData.title}
                    onClickQuestionAnswer={props.onClickQuestionAnswer}
                    answers={props.questionData.answers}
                    timeLeft={props.timeLeft}
                />);
        
        //FINISHED ANSWERING, WAITING FOR RESULT
        case QuizPhaseEnum.ANSWERED:
            return (
                <CreateAnsweredQuestionWaitingScreen
                    userEmoji={props.userName}
                />
            );

        //RESULT OF ANSWERED QUESTION
        case QuizPhaseEnum.RESULT:
            return (
                <CreateAnswerResults
                    userName={props.userName}
                    answerResultData={props.answerResultData}
                    allAnswers={props.questionData.answers}
                />);
            
        //QUIZ FINISHED, FINAL RESULT DISPLAYED
        case QuizPhaseEnum.FINISHED:
            return (
                <CreateFinalResult
                    resultList={props.finalResultList}
                    userScore={props.answerResultData.currentUsersScoreData.score}
                    position={props.answerResultData.currentUsersScoreData.position}
                    userEmoji={props.userName}
                />
            );

        //LOADING
        case QuizPhaseEnum.LOADING:
            return (
                <CreateLoader
                    message={"Laster..."}
                />
            );
            
        default:
            return (
                <CreateErrorAlert
                    message={"Noe gikk galt!"}
                />
            );
    };
};

export default QuizPhaseMediator;