const RenderUserEmoji = (userName) => {

    const separator = " ";
    const codePrefix = "0x";

    if(isNaN(userName) === false)
    {
        return String.fromCodePoint(userName);
    }

    var formattedEmoji = "";

    // Divide emoji code by separator
    const emojiParts = userName.split(separator);
    
    for(const emojiPart of emojiParts)
    {
        // If emoji code segment has no 0x prefix - this is a nummber that should be added at the end. 
        if(emojiPart.indexOf(codePrefix) === -1)
        {
            formattedEmoji = formattedEmoji + " " + emojiPart;
            break;
        }

        // Join formatted emoji part segments to one string
        formattedEmoji = formattedEmoji + String.fromCodePoint(emojiPart);
    }

    return formattedEmoji;
}

export default RenderUserEmoji;