import Button from 'react-bootstrap/Button';
import React from 'react';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import IsValidProperty from '../../Utilities/Validator';

/**
 * @param {Function} SubmitNewQuiz
 * @param {Function} returnToAdminPage
 * @param {Function} setCurrentActivity
 * @param {Array} quizActivities
 * @param {Object} currentActivity
 */
const CreateNewQuiz = ({
    SubmitNewQuiz, 
    returnToAdminPage, 
    setCurrentActivity, 
    quizActivities, 
    currentActivity
}) =>{

    // Gets the current date and time in a format that is easy to handle (yyyy-MM-dd).
    const date = new Date().toJSON().split("T");
    const currentDate = date[0];
    const currentTime = date[1].substr(0, 5);

    const PopulateDropdown = (itemList) => {
        const dropdownItems = itemList.map((item, index) => {
            return(
                <Dropdown.Item 
                    key={index} 
                    onClick={() => setCurrentActivity(item)}
                >
                    {item.name}
                </Dropdown.Item>
            );
        });

        return dropdownItems;
    };

    return(
        <section className="row">
            <div className="col-2">
                <Button variant="primary" onClick={returnToAdminPage}>
                    Gå tilbake
                </Button>
            </div>

            <label htmlFor="activityDropdownButton">Velg aktivitet</label>
            <DropdownButton 
                id="activityDropdownButton" 
                title={IsValidProperty(currentActivity.name) === true ? currentActivity.name : "Velg aktivitet for quiz"}
                className="mb-4"
            >
                {PopulateDropdown(quizActivities)}
            </DropdownButton>

            <Form onSubmit={SubmitNewQuiz}>
                <FormGroup className="col-6 offset-3">
                    <FormLabel>
                        Gi quizen et navn:
                    </FormLabel>
                    <FormControl type="text" name="name" placeholder="Quiz navn" required/>
                    <FormLabel>
                        Skriv en kort beskrivelse av quizen (valgfri):
                    </FormLabel>
                    <FormControl type="text" name="description" placeholder="Quiz beskrivelse"/>
                </FormGroup>
                <FormGroup className="col-6 offset-3">
                    <FormLabel>
                        Velg ønsket starttidspunkt for quizen (valgfri):
                    </FormLabel>
                    <FormControl type="date" name="date" defaultValue={currentDate} min={currentDate}/>
                    <FormControl type="time" name="time" defaultValue={currentTime}/>
                </FormGroup>
                <Button 
                    variant="primary" 
                    type="submit" 
                >
                    Start oppretting av quiz
                </Button>
            </Form>
        </section>
    );
};

export default CreateNewQuiz;