import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import QuizPage from './QuizPage';
import AdminMenuPage from './AdminMenuPage';
import { msalConfig, loginRequest } from './components/Auth/authConfig';

export default class App extends Component {
	static displayName = App.name;

	render() {
		const msalInstance = new PublicClientApplication(msalConfig);

		return (
			<main className='container-fluid'>
				<Routes>
					<Route exact path='/' element={<QuizPage />} />
					<Route path='/admin' element={
						
						/** Force login when accessing /admin */
						<MsalProvider instance={msalInstance} >
							<MsalAuthenticationTemplate
								interactionType={InteractionType.Redirect}
								authenticationRequest={loginRequest}

								errorComponent={() => {
									return (<p>En feil skjedde når vi prøvde å logge deg inn</p>);
								}}
								loadingComponent={() => {
									return (<p>Logger deg inn...</p>);
								}}
							>
								{/** Only rendering admin pages if user is logged in */}
								<AdminMenuPage authInstance={msalInstance} />
							</MsalAuthenticationTemplate>
						</MsalProvider>
					} />
				</Routes>
			</main>
		);
	}
}