import React from 'react';
import RenderUserEmoji from './EmojiRenderer';
/**
 * @param {Array} resultList
 */

const AdminTopList = ({resultList}) => {
    
    const MapResults = (resultList) => {
        const mappedResults = resultList
            .map((element, index) => {
                return(
                    <li className="toplist-element" key={index}>
                        plass {RenderUserEmoji(element.userEmoji)} - {element.score} poeng
                    </li>
                );
            });

        return mappedResults;
    };


    return(
        <div className="admin-top-list">
            <p>
                Foreløpig toppliste:
            </p>    
            <ol className='finalResultList'>
                {MapResults(resultList)}
            </ol>
        </div>
    )
}

export default AdminTopList;