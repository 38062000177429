import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

/**
 * @param {string} quizName
 * @param {string} quizId
 * @param {string} primaryButtonLabel
 * @param {string} secondaryButtonLabel
 * @param {string} secondaryButtonVariant
 * @param {Function} onClickPrimaryButton
 * @param {Function} onClickSecondaryButton
 */
const QuizEditButton = (
    {
        quizName,
        quizId,
        primaryButtonLabel, 
        secondaryButtonLabel,
        secondaryButtonVariant,
        onClickPrimaryButton, 
        onClickSecondaryButton
    }
) => {

    return(
        <li>
            <h6>Quiznavn: {quizName}</h6>
            <ButtonGroup className="mb-3">
                <Button 
                    variant="primary"
                    onClick={() => onClickPrimaryButton(quizId)}
                >
                    {primaryButtonLabel}
                </Button>
                <Button 
                    variant={secondaryButtonVariant}
                    onClick={() => onClickSecondaryButton(quizId)}
                >
                    {secondaryButtonLabel}
                </Button>
            </ButtonGroup>
        </li>
    );
};

export default QuizEditButton;