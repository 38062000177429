import React from 'react';
import CreateConfettiAnimation from '../RenderingComponents/CreateConfettiAnimation';

/**
 * @param {Array} resultList
 * @param {number} userScore
 * @param {number} position
 * @param {string} userEmoji
 */
const CreateFinalResult = ({resultList, userScore, position, userEmoji}) => {

    const MapResults = () => {
        const mappedResults = resultList
            .map((element, index) => {
                return (
                    <li className="toplist-element" key={index}>
                        plass {element.userEmoji} - {element.score} poeng
                    </li>
                );
            });

        return mappedResults;
    };

    let confettiAnimation = null;
    let congratulationsMessage = null;
    if (position < 6) {
        confettiAnimation = CreateConfettiAnimation();
        congratulationsMessage = <p className="positionText">Du kom på topplisten - bra jobbet</p>
    }

    return (
        <>
            {confettiAnimation}
            <div className="row text-center quiz-container">
                <div className="col-12 toplist-container">
                    <p className="positionText">Gratulerer med vel gjennomført quiz!</p>
                    {congratulationsMessage}
                    <ol className="finalResultList">
                        {MapResults()}
                    </ol>

                    <p className="toplist-text">{userEmoji} - Du kom på {position}. plass med {userScore} poeng!</p>
                </div>
            </div>
        </>
    );
};

export default CreateFinalResult;