import React from 'react';
import CreateLoader from '../RenderingComponents/Loader';

const CreateAnsweredQuestionWaitingScreen = ({userEmoji}) => {
    return(
        <div className="row">
            <div className="col-12">
                <h2 className="username-text text-center">{userEmoji}</h2>
                <h4 className="text-center">Venter på at alle deltagere skal bli ferdig å svare</h4>
                <CreateLoader message={"Poengene regnes ut..."} />
            </div>
        </div>
    );
};

export default CreateAnsweredQuestionWaitingScreen;