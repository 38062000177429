import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

import { SecureGET, SecurePATCH } from '../Utilities/FetchApiMethods';
import IsValidProperty from '../Utilities/Validator';

import ActivityEditModal from "./Activity/ActivityEditModal";
import CreateLoader from '../RenderingComponents/Loader';
import CreateErrorAlert from '../RenderingComponents/ErrorAlert';
import InfoCard from "../RenderingComponents/InfoCard";

import QuestionFillIcon from '../Icons/QuestionFillIcon';
import MapPinFillIcon from "../Icons/MapPinFillIcon";
import FileRichTextIcon from "../Icons/FileRichTextIcon";
import GlobeIcon from '../Icons/GlobeIcon';


const ApiBaseUrl = "/api/Activity/";
const GetAllActivitiesUrl = ApiBaseUrl + "GetAllActivities";
const UpdateActivityUrl = ApiBaseUrl + "UpdateActivity";

const DetermineActivityType = (activityType, isActive) => {
    let iconClass = "text-secondary";
    if (isActive === true) {
        iconClass = "text-success";
    }

    switch (activityType) {
        case 0:
            return {
                component: (<MapPinFillIcon customClass={iconClass} />),
                text: "Kartaktivitet"
            }
        case 1:
            return { 
                component: (<FileRichTextIcon customClass={iconClass} />),
                text: "Enkel side"
            }
        case 2:
            return {
                component: (<GlobeIcon customClass={iconClass} />),
                text: "Nettside"
            }
        default:
            return {
                component: (<QuestionFillIcon customClass={iconClass} />),
                text: "Ukjent aktivitetstype"
            }
    }
}


const ActivityAdminPage = ({
    authInstance
}) => {
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [allActivities, setAllActivities] = useState([]);

    useEffect(() => {
        GetAllActivities();
    }, []);

    
    const GetAllActivities = () => {
        setIsLoading(true);

        SecureGET(GetAllActivitiesUrl, authInstance)
            .then(result => {
                if (!result.ok) {
                    console.error("Could not get all activities");
                    throw new Error(result);
                }
                return result.json();
            })
            .then(data => {
                setAllActivities(data);
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const UpdateActivity = (insertModel) => {
        setIsLoading(true);

        SecurePATCH(UpdateActivityUrl, authInstance, insertModel)
            .then(result => {
                if (!result.ok) {
                    console.error("Could not update activity");
                    throw new Error(result);
                }
            })
            .then(() => {
               GetAllActivities();
            })
            .catch(error => {
                console.error(error);
                setHasError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const loaderComponent = () => {
        if (isLoading === true) return <CreateLoader message="Laster..." />
    }

    const errorComponent = () => {
        if (hasError === true) return <CreateErrorAlert message={"Noe gikk galt.."} />
    }

    const mapActivities = () => {
        if (IsValidProperty(allActivities) === false && isLoading === false) {
            return (<Alert variant="warning" className="text-center">
                Det er ingen aktiviteter tilgjengelig
            </Alert>);
        }

        return allActivities.map((element, index) => {
            const activityName = element.name;
            const activityDescription = element.description;
            const activityType = DetermineActivityType(element.activityType, element.active);

            let isActiveText = "nei";
            if(element.active === true){
                isActiveText = "ja";
            }

            return (
                <InfoCard
                    key={index}
                    cardColSize="col-12 col-md-10 col-xl-6"
                    cardTextModel={{
                        title: activityName,
                        description: activityDescription
                    }}
                    cardBodyComponents={<>
                        <p>Type: {activityType.text}</p>
                        <p>Aktiv: {isActiveText}</p>
                        
                        <ActivityEditModal 
                            relevantActivity={element}
                            updateActivityFunc={UpdateActivity}
                        />
                    </>}
                    iconComponent={activityType.component}
                />
            );
        });
    }

    return (
        <article className="text-center">
            <header>
                <h1>Administrering av aktiviteter</h1>
                <h4>Her kan du redigere på de tilgjengelige aktivitetene.</h4>
            </header>
            <section>
                {loaderComponent()}
                {errorComponent()}
            </section>
            <section className="activities">
                <div className="row gap-3 justify-content-center">
                    {mapActivities()}
                </div>
            </section>
        </article>
    );
}

export default ActivityAdminPage;