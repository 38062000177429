/**
 * @param {Component} iconComponent The finished component that will be rendered on the right side of the card 
 * @param {string} cardColSize Bootstrap col sizes
 * @param {object} cardTextModel An object with title and description properties. Should contain the text for the card
 * @param {Component} cardBodyComponents The finished component(s) for the bottom of the card. 
 * @param {string} iconComponentClassName The class name for the icon component. Has default value. 
 * @param {Component} cardHeaderComponents Components that will be rendered in the cards heading. If set to null, no header is rendered
 */
const InfoCard = ({
    iconComponent,
    cardColSize = "",
    cardTextModel,
    cardBodyComponents,
    iconComponentClassName = "d-none d-md-flex col-md-4 info-card-body order-2",
    cardHeaderComponents = null
}) => {
    
    let cardHeader = null;
    if(cardHeaderComponents !== null){
        cardHeader = (<div className="card-header info-card-header">
            {cardHeaderComponents}
        </div>);
    }

    return (
        <div className={"card " + cardColSize + " infoCard"}>
            {cardHeader}
            <div className="row">

                <div className="card-body col-12 col-md-8 order-1">
                    <h3 className="card-title">{cardTextModel.title}</h3>
                    <p className="card-text">{cardTextModel.description}</p>
                    
                    {cardBodyComponents}
                </div>

                <div className={iconComponentClassName}>
                    {iconComponent}
                </div>
            </div>
        </div>
    );
}

export default InfoCard;