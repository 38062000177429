import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

/**
 * @param {string} message
 */
const CreateLoader = (props) => {
    return (
        <div className="row text-center loader">
            <div className="col-12">
                <Spinner animation="border"/>               
            </div>
            <div className="col-12 loader-text">
                {props.message}
            </div>
        </div>
    );
};

export default CreateLoader;