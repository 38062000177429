import React from 'react';
import { Button, Form, Card, Row, Col, Alert } from 'react-bootstrap';
import IsValidProperty from '../../Utilities/Validator';
import QuestionEditingModal from './QuestionEditingModal';
import QuestionCreationModal from './QuestionCreationModal';

/**
 * @param {Object} quiz
 * @param {Function} returnToAdminPage
 * @param {Function} deleteAnswer
 * @param {Function} addQuestion method that calls the api and adds a new question
 * @param {Function} deleteQuestion method that calls the api and deletes the given question
 * @param {Function} updateQuiz Saves the changes to the db
 * @param {Object} apiUpdateMessageModel Model that contains update message and alert variant
 */
const QuizEditPanel = ({
    quiz,
    returnToAdminPage,
    deleteAnswer,
    addAnswer,
    addQuestion,
    deleteQuestion,
    updateQuiz,
    updateQuestionAndAnswers,
    apiUpdateMessageModel
}) => {

    const MapQuestions = (quiz) => {
        const mappedQuestions = quiz.questions
            .map((element, index) => {
                return (
                    <Card key={index} border='secondary' className="mb-3">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={{ span: 8, order: 1 }}>
                                    <h5>
                                        {index + 1} - {element.title}
                                    </h5>
                                </Col>
                                <Col xs={12} md={{ span: 2, order: 2 }} className="mt-3 mb-3 mt-md-0 ">
                                    <div className="d-flex justify-content-center">
                                        <QuestionEditingModal
                                            question={element}
                                            deleteAnswer={deleteAnswer}
                                            addAnswer={addAnswer}
                                            updateQuestionAndAnswers={updateQuestionAndAnswers}
                                        />
                                    </div>
                                </Col>
                                <Col xs={{ span: 12, order: 4 }} md={{ span: 2, order: 3 }} className="mt-3 mb-3 mt-md-0">
                                    <div className="d-flex justify-content-center">
                                        <Button className='delete-question-button'
                                            variant="danger"
                                            onClick={() => deleteQuestion(element.id)}
                                        >
                                            Slett
                                        </Button>
                                    </div>
                                </Col>

                                <Col xs={12} md={{ span: 12, order: 4 }}>
                                    {MapAnswersOverview(element.answers)}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                );
            });

        return mappedQuestions;
    };

    const MapAnswersOverview = (answers) => {
        const mappedAnswers = answers
            .map((element, index) => {

                let correctAnswer = "";
                if (element.isCorrectAnswer === true) {
                    correctAnswer = "correctAnswer";
                }

                return (
                    <li key={index} className={correctAnswer}>
                        <span>{element.title}</span>
                    </li>
                );
            });

        return (<ol> {mappedAnswers} </ol>);
    };

    const GetStartDate = (startDateTime) => {
        let startDate = new Date().toJSON();

        if (IsValidProperty(startDateTime)) {
            startDate = startDateTime;
        };

        return startDate.split("T")[0];
    };

    const GetStartTime = (startDateTime) => {
        let startTime = new Date().toJSON();

        if (IsValidProperty(startDateTime)) {
            startTime = startDateTime;
        };

        return startTime.split("T")[1].substring(0, 5);
    };


    const RenderUpdateStatus = () => {
        if (apiUpdateMessageModel === null) return null;

        return (<Alert
            variant={apiUpdateMessageModel.variant + " text-center"}
            className='quizUpdateAlert'
        >
            <p>{apiUpdateMessageModel.message}</p>
        </Alert>
        );
    }


    return (<>
        <header>
            <Row>
                <Col xs={12}>
                    <Button variant="primary" onClick={returnToAdminPage}>
                        Gå tilbake
                    </Button>
                </Col>
            </Row>
        </header>
        <section id="quiz-editing-page">
            <Form id="quiz-editing-form" onSubmit={updateQuiz}>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-4" controlId="quiz-title">
                            <Form.Label>Tittel</Form.Label>
                            <Form.Control type="text" name="title" defaultValue={quiz.quizTitle} />
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group className="mb-4" controlId="quiz-description">
                            <Form.Label>Beskrivelse</Form.Label>
                            <Form.Control as="textarea" rows={3} name="description" defaultValue={quiz.quizDescription} />
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group className="mb-4" controlId="quiz-order">
                            <Form.Label>Rekkefølge</Form.Label>
                            <Form.Control type="number" name="order" defaultValue={quiz.quizOrder} />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="quiz-start-date">
                            <Form.Label>Startdato</Form.Label>
                            <Form.Control type="date" name="date" defaultValue={GetStartDate(quiz.startTime)} />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Form.Group controlId="quiz-start-time">
                            <Form.Label>Starttid</Form.Label>
                            <Form.Control type="time" name="time" defaultValue={GetStartTime(quiz.startTime)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={12}>
                        {RenderUpdateStatus()}
                    </Col>
                    <Col xs={12}>
                        <div className="d-flex justify-content-center justify-content-md-start">
                            <Button variant="primary" type="submit">
                                Lagre endringer
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col>
                    {MapQuestions(quiz)}
                </Col>
            </Row>

            <Row className="mt-3 mb-4">
                <Col>
                    <div className="d-flex justify-content-center justify-content-md-start">
                        <QuestionCreationModal 
                            addQuestion={addQuestion}
                        />
                    </div>
                </Col>
            </Row>
        </section>
    </>);
};

export default QuizEditPanel;