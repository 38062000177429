import React from 'react';
import RenderUserEmoji from '../RenderingComponents/EmojiRenderer';
import CreateConfettiAnimation from '../RenderingComponents/CreateConfettiAnimation';
import IsValidProperty from '../Utilities/Validator';

const pointsEmoji = (<span>&#11088;</span>);
const rightAnswerEmoji = (<span>&#10004;</span>);
const wrongAnswerEmoji = (<span>&#10060;</span>);


/**
 * @param {string} userName
 * @param {Array} allAnswers
 * @param {Object} answerResultData //All parameters below exist as props inside this object
 * @param {Array} answers
 * @param {number} pointsForCurrentQuestion
 * @param {number} score
 * @param {bool} answeredCorrect
 * @param {number} position
 * @param {number} pointsToNextPosition
 * @param {string} nextPositionUserName
 */
const CreateAnswerResults = (
	{
		userName,
		allAnswers,
		answerResultData: {
			answers,
			currentUsersScoreData: {
				pointsForCurrentQuestion,
				score,
				answeredCorrect,
				position,
				pointsToNextPosition,
				nextPositionUserName
			}
		}
	}
) => {

	// Here the user joined the quiz during a question. A bug therefore leads allAnswers to be undefined.
	const userJoinedDuringQuestion = IsValidProperty(allAnswers) === false;

	const RenderCurrentPosition = () => {
		if (position === 0) {
			return (<>
				<p className="text-center">Ser ut som du ble med litt sent. Ikke bekymre deg, neste spørsmål kommer straks.</p>
				<p className="positionText text-center">Er du klar?</p>
			</>);
		}

		if (position === 1) {
			return (<p className="text-center">
				Du er på førsteplass med {score} poeng! Godt jobbet!
			</p>);
		};

		return (<>
			<p className="positionText text-center">
				Du er på {position}. plass med {score} poeng
			</p>
			<p className="positionText text-center">
				Du ligger {pointsToNextPosition} poeng bak {RenderUserEmoji(nextPositionUserName)}
			</p>
		</>);
	}

	const RenderAnswers = () => {
		if (userJoinedDuringQuestion === true) {
			return null;
		}

		const mappedAnswers = allAnswers.map((element, index) => {
			let className = "wrongAnswerQuiz";
			let answerEmoji = wrongAnswerEmoji;

			if (answers.some(x => x.id === element.id)) {
				className = "correctAnswerQuiz";
				answerEmoji = rightAnswerEmoji;
			};

			return (<p key={index} className={`answer-alternative-result ${className}`}>{answerEmoji} {element.title}</p>)
		});

		return (<div className="col-12">
			<h3 className="heading">Fasit:</h3>
			{mappedAnswers}
		</div>);
	};

	let confettiAnimation = null;
	let answerHeading = "Du svarte feil";
	let emojiForPoints = null;
	let scoredPoints = (<span>0 poeng</span>);

	if (userJoinedDuringQuestion === true) {
		answerHeading = "Fikk ikke et svar fra deg";
		scoredPoints = null;
	}

	if (answeredCorrect === true) {
		confettiAnimation = CreateConfettiAnimation();
		answerHeading = "Du svarte riktig";
		emojiForPoints = pointsEmoji;
		scoredPoints = (<span>+{pointsForCurrentQuestion} poeng</span>);
	};

	return (<>
		{confettiAnimation}
		<div className="row quiz-container result-container text-center">
			<div className="col-12">
				<h1>{answerHeading}</h1>
				<h2 className="scored-points">{emojiForPoints} {scoredPoints}</h2>
			</div>
			
			<div className="col-12">
				<h2 className="text-center username-text">{RenderUserEmoji(userName)}</h2>
				{RenderCurrentPosition()}
			</div>

			{RenderAnswers()}

		</div>
	</>
	);
};

export default CreateAnswerResults;