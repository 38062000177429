import React, { useState } from 'react';
import { Button, Modal, Form, Container, Col, Row } from 'react-bootstrap';

const QuestionCreationModal = ({
    addQuestion
}) => {
    const [showModal, setModalShow] = useState(false);
    const [questionTitle, setQuestionTitle] = useState("");
    const [questionOrder, setQuestionOrder] = useState("");
    
    const handleClose = () => {
        // wipe state
        setQuestionTitle("");
        setQuestionOrder(0);

        // close modal
        setModalShow(false);
    }

    const validateQuestionOrder = (newOrder) => {
        if (newOrder < 0) return;

        setQuestionOrder(newOrder);
    }

    const submitQuiz = (event) => {
        event.preventDefault();

        const insertModel = {
            questionTitle: questionTitle,
            questionOrder: questionOrder
        }

        addQuestion(insertModel);
        handleClose();
    }

    return(
        <>
            <Button className="question-creation-button"
                variant="success"
                onClick={() => setModalShow(true)}
            >
                Legg til spørsmål
            </Button>

            <Modal className="question-creation-modal" 
                show={showModal} 
                onHide={handleClose}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Legg til spørsmål
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form id="question-creation-form" onSubmit={submitQuiz}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-3" controlId="question-title">
                                        <Form.Label>Spørsmål</Form.Label>
                                        <Form.Control type="text" placeholder="Skriv spørsmålsteksten her" 
                                            value={questionTitle}
                                            onChange={(e) => setQuestionTitle(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-5" controlId="question-order">
                                        <Form.Label>Rekkefølge</Form.Label>
                                        <Form.Control type="number"
                                            value={questionOrder}
                                            onChange={(e) => validateQuestionOrder(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <div className="d-flex justify-content-center justify-content-md-end">
                                        <Button className="mt-3" variant="success" type="submit">
                                            Legg til
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default QuestionCreationModal;