const QuizPhaseEnum = {
    INITIAL: 0,
    WAITING_TO_BEGIN: 1,
    QUESTION: 2,
    ANSWERED: 3,
    RESULT: 4,
    FINISHED: 5,
    LOADING: 6
};

Object.freeze(QuizPhaseEnum);
export default QuizPhaseEnum;